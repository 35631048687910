import React from 'react';
import { useSelector } from 'react-redux';
import { getPopups } from '../selectors';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import SupplierBillingView from '../components/SupplierBillingView';

function SupplierBillingApp() {
  const popups = useSelector(getPopups);
  return (
    <div>
      <Header />
      <MainSection popups={popups}>
        <SupplierBillingView />
      </MainSection>
      <Overlay popups={popups} />
    </div>
  );
}

export default SupplierBillingApp;
