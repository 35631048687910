import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Table, THead, TBody, TR, TH, TD, Loading } from '@commonsku/styles';
import { oauth, getIdentityUtils } from '../utils';
import { useHasCapabilities } from '../hooks';
import ReportTabsContainer from './ReportTabsContainer';

function SupplierBillingView() {
  const canManageBilling = useHasCapabilities(['FEATURE-ADMIN', 'BILL-SUPPLIER']);
  const [isLoading, setLoading] = useState(true);
  const [billingPeriods, setBillingPeriods] = useState([]);
  useEffect(() => {
    oauth('GET', 'billing-period', { actionName: 'supplier-billing' }).then(
      ({ json }) => {
        setBillingPeriods(json.billing_periods);
        setLoading(false);
      }
    );
  }, []);

  const handleRegenerateBilling = (billing_period_id) => {
    oauth('POST', 'billing-period', {
      actionName: 'queue-prepare-close',
      billing_period_id,
      preview: 0,
      redo: 1
    }).then(
      ({ json }) => {
        setBillingPeriods(bps => bps.map(
          bp => bp.billing_period_id === billing_period_id ? { ...bp, ...json.billing_period } : bp
        ));
      }
    );
  };

  const handleFinalizeBilling = (billing_period_id) => {
    oauth('POST', 'billing-period', {
      actionName: 'queue-finalize-close',
      billing_period_id,
      preview: 0,
      redo: 1
    }).then(
      ({ json }) => {
        setBillingPeriods(bps => bps.map(
          bp => bp.billing_period_id === billing_period_id ? { ...bp, ...json.billing_period } : bp
        ));
      }
    );
  };

  return (
    <div className="main-content" style={{ paddingRight: '12px' }}>
      <ReportTabsContainer page="supplier_billing" csku={true} />
      <Row>
        <Col>
        {isLoading ?
          <Loading /> : (
            billingPeriods.length > 0 ?
            <Table>
              <THead>
                <TR>
                  <TH>Billing Period</TH>
                  <TH>Status</TH>
                  <TH>Total</TH>
                  <TH>Details</TH>
                  <TH>Summary</TH>
                  <TH>Comparison</TH>
                  <TH>&nbsp;</TH>
                </TR>
              </THead>
              <TBody>
                {billingPeriods.map(
                  bp => (
                    <TR key={bp.billing_period_id}>
                      <TD>{bp.billing_period_name}</TD>
                      <TD>{bp.status}</TD>
                      <TD>{bp.total}</TD>
                      <TD>
                        {bp.reports['CHARGE-DETAILS'] ?
                        <a href={`/artifact/${bp.reports['CHARGE-DETAILS']}`} target="_blank">Details</a> :
                        <span>&mdash;</span>}
                      </TD>
                      <TD>
                        {bp.reports['CHARGE-SUMMARY'] ?
                        <a href={`/artifact/${bp.reports['CHARGE-SUMMARY']}`} target="_blank">Summary</a> :
                        <span>&mdash;</span>}
                      </TD>
                      <TD>
                        {bp.reports['CHARGE-SUMMARY'] ?
                        <a href={`/artifact/${bp.reports['CHARGE-COMPARISON']}`} target="_blank">Comparison</a> :
                        <span>&mdash;</span>}
                      </TD>
                      <TD>
                        {bp.status === 'GENERATING' && 'Generating'}
                        {bp.status === 'FINALIZING' && 'Finalizing'}
                        {bp.status === 'PENDING' && bp.reports['CHARGE-DETAILS'] && canManageBilling &&
                          <Button size="small" style={{ margin: '1rem' }} onClick={() => handleRegenerateBilling(bp.billing_period_id)}>Regenerate</Button>
                        }
                        {bp.status === 'PENDING' && bp.reports['CHARGE-DETAILS'] && canManageBilling &&
                          <Button size="small" style={{ margin: '1rem' }} onClick={() => handleFinalizeBilling(bp.billing_period_id)}>Finalize</Button>
                        }
                      </TD>
                    </TR>
                  )
                )}
              </TBody>
            </Table> :
            'No billing periods found'
          )}
        </Col>
      </Row>
    </div>
  );
}
export default SupplierBillingView;
