import { get } from 'lodash';
import React from 'react';

import { useIdentity } from '../hooks';
import config from '../config';

const ReportTabsContainers = ({ page, csku, style={}, className='' }) => {
    const identity = useIdentity();
    const { nav_container_style, nav_style, li_style, li_active_style, a_style, a_active_style } = styles;
    const isVandelay = identity.company_id === '60d613ea-c3db-4265-9a46-442553c8619a';

    return (
        <div className={["row", "collapse", className].join(' ')} style={{...nav_container_style, ...style}}>
            <div className="cs-nav" style={nav_style}>
                {!csku ?
                    <ul>
                        {!isVandelay ? <li style={li_style}>
                            <a style={a_style} href="admin.php">Users</a>
                        </li> : null}
                        {!isVandelay ? <li style={li_style}>
                            <a style={a_style} href="admin_groups.php">Groups</a>
                        </li> : null}
                        <li style={li_style}>
                            <a style={a_style} href="admin_settings.php">Settings</a>
                        </li>
                        {!isVandelay ? <li style={li_style}>
                            <a style={a_style} href="admin_permissions.php">Permissions</a>
                        </li> : null}
                        <li style={li_style}>
                            <a style={a_style} href="admin_templates.php">Email Settings</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="admin_commissions.php">Commissions</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="admin_decoration_matrix.php">Decoration Matrix</a>
                        </li>
                        {
                            (get(config, 'avalara.beta_companies') || [identity.company_id]).indexOf(identity.company_id) > -1 &&
                            <li style={page === 'admin_avalara' ? li_active_style : li_style}>
                                <a style={page === 'admin_avalara' ? a_active_style : a_style} href="admin_avalara.php">
                                    Avalara
                                </a>
                            </li>
                        }
                    </ul>
                :
                    <ul>
                        <li style={li_style}>
                            <a style={a_style} href="report_company_pages.php">Company Pages</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="report_company_status.php">Company Status</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="report_company_sales_dashboard.php">Active Trials</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="report_company_users.php">Company Users</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="report_company_activities.php">Activities</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="report_latest_products.php">Latest Products</a>
                        </li>
                        <li style={li_style}>
                            <a style={a_style} href="report_company_mailing_list.php">Mailing List</a>
                        </li>
                        <li style={page === 'license_change' ? li_active_style : li_style} className="active">
                            <a style={page === 'license_change' ? a_active_style : a_style} href="report_license_change.php">License Change</a>
                        </li>
                        <li style={page === 'supplier_billing' ? li_active_style : li_style} className="active">
                            <a style={page === 'supplier_billing' ? a_active_style : a_style} href="report_supplier_billing.php">Supplier Billing</a>
                        </li>
                    </ul>
                }
            </div>
        </div>
    );
};

const styles = {
    nav_container_style: {
        maxWidth: 'initial',
        fontSize: '14px',
        marginBottom: '10px',
        marginTop: '-35px'
    },
    nav_style: {
        marginBottom: '20px',
        height: '34px',
    },
    li_style: {
        position: 'relative',
        display: 'block',
        float: 'left',
        borderBottom: '1px solid #eaeaea',
        borderWidth: '1px 0',
    },
    li_active_style: {
        position: 'relative',
        display: 'block',
        float: 'left',
    },
    a_style: {
        color: '#5ba0b4',
        display: 'inline-block',
        padding: '7px 7px',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        paddingBottom: '6px'
    },
    a_active_style: {
        color: '#5ba0b4',
        padding: '9px 4px',
        position: 'relative',
        fontWeight: 700,
        height: '17px',
        borderBottom: '3px solid #5ba0b4',
        paddingBottom: '7px',
        top: '7px'
    }
};

export default ReportTabsContainers;
